/*error.scss*/
@import '../_config';
/*
    error.css
    Wezom wTPL v4.0.0
*/
.errorPage {
    overflow-x: hidden;
}
.errorWrapper {
    display: table;
    width: 100%;
    min-height: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: $colorLight;
    background: -moz-radial-gradient(center, ellipse cover,  $colorWhite 0%, $colorWhite 22%, darken($colorLight, 5%) 60%);
    background: -webkit-radial-gradient(center, ellipse cover,  $colorWhite 0%,$colorWhite 22%,darken($colorLight, 5%) 60%);
    background: radial-gradient(ellipse at center,  $colorWhite 0%,$colorWhite 22%,darken($colorLight, 5%) 60%);
}
.no-moz .errorWrapper {
    height: auto !important;
}

.errorHolder {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.errorContent {
    display: block;
    max-width: 640px;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
    .wTxt {
        text-align: center;
    }
}

.errorHeader {
    @extend %color;
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    font-size: 200px;
    line-height: $emSize;
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 130%;
        left: -15%;
        bottom: 0;
        height: 20%;
        filter: blur(4px);
        opacity: .5;
        background: -moz-radial-gradient(center, ellipse cover,  $colorMiddle 0%, rgba($colorMiddle,0) 60%);
        background: -webkit-radial-gradient(center, ellipse cover,  $colorMiddle 0%,rgba($colorMiddle,0) 60%);
        background: radial-gradient(ellipse at center,  $colorMiddle 0%,rgba($colorMiddle,0) 60%);
    }
}
%color {
    color: $colorDark;
}

@media only screen and (max-width: 720px) {
    .errorHeader {
        font-size: 150px;
    }
}
@media only screen and (max-width: 480px) {
    .errorHeader {
        font-size: 100px;
    }
}